.ui.menu.main {
    border-radius:0;
}
.text-left {
    text-align:left;
}
.ui.middle.grid {
    height:100%;
}
.column.login {
    max-width:450px;
}